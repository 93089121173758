import {ActionTypes} from '../actions/allActions';

const rootReducer = (state, action) => {
  console.log("IN ROOT REDUCER")
  switch (action.type) {
    
    case ActionTypes.CHANGE_FEED_SORT: {
      let newSort = action.payload;

      // Either 'hot' or 'new'
      if(newSort !== 'hot' && newSort !== 'new') {
        return state;
      }
      return {...state, feedSort: newSort};
    }

    case ActionTypes.SET_WELCOME_PAGE_HIDDEN: {
      console.log(action)
      return {...state, isWelcomePageHidden: action.payload};
    }

    default:
      return state;
  }
};

export default rootReducer;