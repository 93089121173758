import React, { useState, useEffect } from 'react';
import './Post.scss';
import CommentImage from '../static/comment.png';
import DownImg from '../static/down-arrow.png';
import UpImg from '../static/up-arrow.png';
import UpImgSelected from '../static/up-arrow-selected.png';
import DownImgSelected from '../static/down-arrow-selected.png';
import IconButton from '@material-ui/core/IconButton';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import CommentFeed from './CommentFeed';
import { Typography } from '@material-ui/core';

/**
 * GQL mutation for voting on a post
 */
const UPVOTE_POST = gql`
  mutation upvote_post($post_id: String!) {
    upvote_post(post_id: $post_id) {
      id
      value
    }
  }
`;

const REMOVE_VOTE = gql`
  mutation remove_vote_post($post_id: String!) {
    remove_vote_post(post_id: $post_id) {
      id
      value
    }
  }
`;

const DOWNVOTE_POST = gql`
  mutation downvote_post($post_id: String!) {
    downvote_post(post_id: $post_id) {
      id
      value
    }
  }
`;

/**
 * Graphql returns the data as either null or an object so we must
 * parse the response to keep things simple (1 for upvoted, 0 for unvoted, -1 for downvoted)
 */
const getUserVoteValue = (graphqlVote) => {
  if (graphqlVote == null) {
    return 0;
  } else {
    return graphqlVote.value;
  }
}


export default function Post(props) {
  const [user_vote, setUserVote] = useState(getUserVoteValue(props.post.user_vote));
  const [post_votes, setPostVotes] = useState(props.post.votes);
  const [upvote] = useMutation(UPVOTE_POST);
  const [remoteVote] = useMutation(REMOVE_VOTE);
  const [downvote] = useMutation(DOWNVOTE_POST);
  const [isCommentFeedHidden, setCommentFeed] = useState(true);

  useEffect(() => {
    setPostVotes(props.post.votes);
    setUserVote(props.post.user_vote);
  }, [props.post])

  const handleUpVote = async () => {
    // Do the logic locally for smooth as butter UX
    if(user_vote === 1) {
      setUserVote(0);
      setPostVotes(post_votes-1);
      
      try {
        await remoteVote({variables: {post_id: props.post.id}});
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    } else {
      if(user_vote === -1) {
        // post was downvoted, now upvoting
        setPostVotes(post_votes+2);
      } else {
        // post was unvoted, now upvoting
        setPostVotes(post_votes+1);
      }

      setUserVote(1);
      
      try {
        await upvote({variables: {post_id: props.post.id}});
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    }

    
  }

  const handleDownVote = async () => {
    if(user_vote === -1) {
      setUserVote(0);
      setPostVotes(post_votes+1);
      
      try {
        await remoteVote({variables: {post_id: props.post.id}});
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    } else {
      if(user_vote === 1) {
        // post was upvoted, now downvoting
        setPostVotes(post_votes-2);
      } else {
        // post was unvoted, now downvoting
        setPostVotes(post_votes-1);
      }

      setUserVote(-1);
      
      try {
        await downvote({variables: {post_id: props.post.id}});
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    }

  }

  const handleCommentClick = () => {
    setCommentFeed(!isCommentFeedHidden);
  }

  return (
    <div tabIndex={0} className="post">
      <div style={{
        flexGrow: '1',
        gridColumn: '1 / 9',
        maxHeight: '50p',
        row: 1
      }}>
        <Typography variant="subtitle1" style={{color: 'black', textAlign: 'center', wordWrap: 'anywhere'}}>{props.post.message}</Typography>
      </div>
      <div className="post-body">
        {props.children}
      </div>
      
      <div className="post-footer">
        <IconButton className='up-vote-button' onClick={handleUpVote}>
          {
            getUserVoteValue(user_vote) === 1 ? (
              <img className="button-image" src={UpImgSelected} alt="Up vote selected"/>
            ) : (
              <img className="button-image" src={UpImg} alt="Up vote unselected"/>
            )
          }
        </IconButton>
        
        <span>{post_votes}</span>
        <IconButton className='down-vote-button' onClick={handleDownVote}>
          {
            getUserVoteValue(user_vote) === -1 ? (
              <img className="button-image" src={DownImgSelected} alt="Down vote selected"/>
            ) : (
              <img className="button-image" src={DownImg} alt="Down vote unselected"/>
            )
          }
        </IconButton>
        
        <div style={{float: 'right', display: 'inline-table'}}>
          <span style={{verticalAlign: 'middle', display: 'table-cell'}}>{props.post.total_comments}</span>
          <IconButton className='comment-button' onClick={handleCommentClick}>
            <img src={CommentImage} alt="Comment button"/>
          </IconButton>
        </div>
      </div>
          {
            !isCommentFeedHidden && <CommentFeed postId={props.post.id}/>
          }
    </div>
  )
}