import React from 'react';
import { setWelcomePageSeen } from '../utils/LocalStorageManager';
import { connect } from 'react-redux';
import { setWelcomePageHidden } from '../redux/actions/allActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'

function WelcomePage(props) {

  const handleProceed = () => {
    setWelcomePageSeen();
    props.setWelcomePageHidden(true);
  }

  return(
    <>
    <div style={{
      backgroundImage: 'url("https://limlight451.s3.amazonaws.com/backdrop.jpg")',
      backgroundPosition: 'center center',
      height: '100vh',
      width: '100vw'
    }}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={5}
        style={{
          height: '100vh',
          width: '100vw',
          margin: '0'
        }}

      >
        <Grid item>
          <Typography variant="h2" align="center" style={{
            color: 'white',
            fontWeight: '1000'
          }}>
            Not for Nudes
          </Typography>
      
        </Grid>
        <Grid item>
        <Typography variant="subtitle1" align="center" style={{
          color: 'white'
        }}>
          Share photos anonymously with the world!
        </Typography>
      
          

        </Grid>
        <Grid item>
        <Button variant="outlined" onClick={handleProceed} style={{
            color: 'white',
            borderColor: 'white'
          }}>proceed</Button>
        </Grid>
        

      </Grid>
      
    </div>
      
    </>
  )
}

const mapDispatchToProps = {
  setWelcomePageHidden,
}

export default connect(null, mapDispatchToProps)(WelcomePage);